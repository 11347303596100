import React, { ReactElement } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFieldArrayRemove,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  createStyles,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  StyleRules,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Cascader } from 'antd';
import i18n from 'i18next';

import debug from '../../../utils/debug';
import dataIndustry from '../../../utils/industry';
import specialData from '../../../utils/special';

const styles = (): StyleRules =>
  createStyles({
    submit: {
      textAlign: 'center',
    },
    imageGird: {
      position: 'relative',
    },
    imageButton: {
      position: 'absolute',
      top: '8px',
      right: '8px',
    },
    text: {
      margin: 'auto 0',
    },
    martop: {
      marginTop: '20px',
    },
    borders: {
      position: 'relative',
      // borderBottom: '1px solid black',
      border: '1px solid #a2a294',
    },
    titlePosition: {
      padding: '0 6px',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '12px',
      position: 'absolute',
      top: '-10px',
      left: '6px',
      backgroundColor: '#FFFFFF',
    },
    cardWidth: {
      width: '100%',
      height: '56px',
      paddingTop: '8px',
    },
    borderButton: {
      width: '100%',
      margin: '20px 0',
    },
  });

const WorkInputView = ({
  classes,
  index,
  control,
  errors,
  remove,
  summaryMode = false,
}: Props): ReactElement => {
  debug('Load work input view');
  const { SHOW_CHILD } = Cascader;
  const { t } = useTranslation();
  i18n.loadNamespaces('WorkInputView');
  return (
    <>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <Tooltip
                TransitionComponent={Zoom}
                title={!summaryMode ? t('work_input_view:corporate_name_tooltip') : ''}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoFocus={false}
                  label={t('work_input_view:corporate_name')}
                  disabled={summaryMode}
                  error={!!errors?.workInfoList?.[index]?.companyName}
                  helperText={
                    errors?.workInfoList?.[index]?.companyName &&
                    t('work_input_view:company_not_empty')
                  }
                  {...field}
                />
              </Tooltip>
            )}
            name={`workInfoList.${index}.companyName`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <Tooltip
                TransitionComponent={Zoom}
                title={!summaryMode ? t('work_input_view:job_title_tooltip') : ''}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoFocus={false}
                  label={t('work_input_view:job_title')}
                  disabled={summaryMode}
                  error={!!errors?.workInfoList?.[index]?.jobTitle}
                  helperText={
                    errors?.workInfoList?.[index]?.jobTitle &&
                    t('work_input_view:position_not_empty')
                  }
                  {...field}
                />
              </Tooltip>
            )}
            name={`workInfoList.${index}.jobTitle`}
            control={control}
            rules={{
              required: true,
            }}
          ></Controller>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => {
              return (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  error={!!errors?.workInfoList?.[index]?.atWorkDateRangeStart}
                  fullWidth
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      variant="inline"
                      views={['year', 'month']}
                      format="yyyy-MM"
                      id="date-picker-start"
                      label={t('work_input_view:entry_time')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={summaryMode}
                      readOnly={summaryMode}
                      {...field}
                      value={field.value ?? null}
                    />
                  </MuiPickersUtilsProvider>
                  {errors?.workInfoList?.[index]?.atWorkDateRangeStart && (
                    <FormHelperText>{t('work_input_view:onboarding_not_empty')}</FormHelperText>
                  )}
                </FormControl>
              );
            }}
            name={`workInfoList.${index}.atWorkDateRangeStart`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <FormControl margin="normal" variant="outlined" fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    variant="inline"
                    format="yyyy-MM"
                    views={['year', 'month']}
                    id="date-picker-end"
                    label={t('work_input_view:time_of_resignation')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={summaryMode}
                    readOnly={summaryMode}
                    {...field}
                    value={field.value ?? null}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            )}
            name={`workInfoList.${index}.atWorkDateRangeEnd`}
            control={control}
          ></Controller>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between" className={classes.martop}>
        <Grid item xs={5}>
          <Grid item xs={12} className={classes.borders}>
            <span className={classes.titlePosition}>{t('work_input_view:major')}</span>
            <Controller
              render={({ field }) => (
                <Cascader
                  bordered={false}
                  disabled={summaryMode}
                  placeholder={t('languages_input_view:please_select')}
                  className={classes.cardWidth}
                  options={specialData}
                  multiple
                  size="large"
                  maxTagCount="responsive"
                  showCheckedStrategy={SHOW_CHILD}
                  {...field}
                />
              )}
              name={`workInfoList.${index}.professionalClass`}
              control={control}
            ></Controller>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid item xs={12} className={classes.borders}>
            <span className={classes.titlePosition}>{t('work_input_view:industry')}</span>
            <Controller
              render={({ field }) => (
                <Cascader
                  bordered={false}
                  disabled={summaryMode}
                  placeholder={t('languages_input_view:please_select')}
                  className={classes.cardWidth}
                  options={dataIndustry}
                  multiple
                  size="large"
                  maxTagCount="responsive"
                  showCheckedStrategy={SHOW_CHILD}
                  {...field}
                />
              )}
              name={`workInfoList.${index}.industryClass`}
              control={control}
            ></Controller>
          </Grid>
        </Grid>
      </Grid>
      <Controller
        render={({ field }) => (
          <Tooltip
            TransitionComponent={Zoom}
            title={!summaryMode ? t('work_input_view:job_description_tooltip') : ''}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              autoFocus={false}
              multiline
              minRows={4}
              id="workContent"
              label={t('work_input_view:job_description')}
              disabled={summaryMode}
              {...field}
            />
          </Tooltip>
        )}
        name={`workInfoList.${index}.jobDescription`}
        control={control}
      ></Controller>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => {
              return (
                <FormControl
                  margin="normal"
                  error={!!errors?.workInfoList?.[index]?.wayOfWorking}
                  fullWidth
                >
                  <InputLabel id="label-work-type-2">
                    {t('work_input_view:working_mode')}
                  </InputLabel>
                  <Select
                    labelId="label-work-type-2"
                    id="wayOfWorking"
                    label={t('work_input_view:Working_mode')}
                    fullWidth
                    disabled={summaryMode}
                    readOnly={summaryMode}
                    {...field}
                  >
                    <MenuItem value={1}>{t('work_input_view:full_time')}</MenuItem>
                    <MenuItem value={2}>{t('work_input_view:part_time_job')}</MenuItem>
                  </Select>
                  {errors?.workInfoList?.[index]?.wayOfWorking && (
                    <FormHelperText>{t('work_input_view:Working_mode_not_empty')}</FormHelperText>
                  )}
                </FormControl>
              );
            }}
            name={`workInfoList.${index}.wayOfWorking`}
            rules={{
              required: true,
            }}
            control={control}
          ></Controller>
        </Grid>
      </Grid>
      {!summaryMode && remove && (
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => {
                remove(index);
              }}
            >
              {t('work_input_view:delete')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.borderButton} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export type ConsultantWorkInfoControl = Control<{
  workInfoList: ConsultantWorkInfo[];
}>;
export type ConsultantWorkInfoSetValue = UseFormSetValue<{
  workInfoList: ConsultantWorkInfo[];
}>;
export type ConsultantWorkInfoErrors = FieldErrors<{
  workInfoList: ConsultantWorkInfo[];
}>;

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  index: number;
  control: ConsultantWorkInfoControl;
  setValue: ConsultantWorkInfoSetValue;
  errors?: ConsultantWorkInfoErrors;
  remove?: UseFieldArrayRemove;
  summaryMode?: boolean;
}

export default withStyles(styles)(WorkInputView);
