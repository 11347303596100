import { navigate } from 'gatsby-link';

import { consultantVerificationKnownAction } from '../../reducers/consultantReducer';
import { loadingEndAction, loadingStartAction } from '../../reducers/loadingReducer';
import { hideNotificationAction, showNotificationAction } from '../../reducers/notificationReducer';
import {
  CONSULTANT_VERIFICATION_ADD_FAIL,
  CONSULTANT_VERIFICATION_GET_FAIL,
  CONSULTANT_VERIFICATION_GET_SUCCESS,
  CONSULTANT_VERIFICATION_GET_SUCCESS_ERROR_CODE,
} from '../../utils/constant';
import debug from '../../utils/debug';
import { mutate, query } from '../../utils/graphql';
import {
  ADD_CONSULTANT_COMMENT,
  DELETE_CONSULTANT_COMMENT,
  GET_CONSULTANT_COMMENT,
} from '../../utils/graphql/consultant/consultantComment';
import {
  ADD_CONSULTANT_VERIFICATION,
  GET_CONSULTANT_VERIFICATION,
  UPDATE_CONSULTANT_VERIFICATION,
} from '../../utils/graphql/consultant/consultantVerification';

// 添加or修改验证信息
export const saveConsultantVerification: SaveConsultantVerificationFunction<ConsultantVerification> = (
  verification: ConsultantVerification
) => async (dispatch: Function): Promise<void> => {
  debug('Add consultant verification');
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = { verification };
  let data, loading, error;
  if (!verification.id) {
    ({ data, loading, error } = await query(ADD_CONSULTANT_VERIFICATION, variables));
  } else {
    ({ data, loading, error } = await query(UPDATE_CONSULTANT_VERIFICATION, variables));
  }
  if (!loading) {
    dispatch(loadingEndAction());
  }
  if (error) {
    dispatch(
      showNotificationAction({
        severity: 'error',
        message: CONSULTANT_VERIFICATION_ADD_FAIL,
      })
    );
  }

  if (data && data?.consultantAddVerification) {
    const updatedVerification = data.consultantAddVerification;
    debug('Add consultant verification successfully');
    debug(updatedVerification);
    dispatch(consultantVerificationKnownAction(updatedVerification));
    dispatch(showNotificationAction({ severity: 'success', message: '信息已保存' }));
    navigate('/consultant/verification');
  }
  if (data && data?.consultantUpdateVerification) {
    const updatedVerification = data.consultantUpdateVerification;
    debug('Update consultant verification successfully');
    debug(updatedVerification);
    dispatch(consultantVerificationKnownAction(updatedVerification));
    dispatch(showNotificationAction({ severity: 'success', message: '信息已保存' }));
    navigate('/consultant/verification');
  }
};

// 获取顾问验证信息
export const getConsultantVerification: GetConsultantVerificationFunction = () => async (
  dispatch: Function
): Promise<void> => {
  debug('Get consultant verification');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const { data, loading, error } = await query(GET_CONSULTANT_VERIFICATION);
  if (!loading) {
    dispatch(loadingEndAction());
  }
  if (error) {
    if (error.code !== CONSULTANT_VERIFICATION_GET_SUCCESS_ERROR_CODE) {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: CONSULTANT_VERIFICATION_GET_FAIL,
        })
      );
    }
  }
  if (data && data?.consultantGetVerification) {
    const updatedVerification = data.consultantGetVerification;
    delete updatedVerification['__typename'];
    debug('Get consultant verification successfully');
    debug(updatedVerification);
    dispatch(consultantVerificationKnownAction(updatedVerification));
    dispatch(
      showNotificationAction({ severity: 'success', message: CONSULTANT_VERIFICATION_GET_SUCCESS })
    );
  }
};

// 顾问评价
export const getConsultantComment = (consultantId: number): Function => async (
  dispatch: Function
): Promise<ConsultantCommentInfo[]> => {
  // dispatch(loadingStartAction());
  // dispatch(hideNotificationAction());
  // dispatch(companyKnownAction({ loaded: false }));
  const { data, error } = await query(GET_CONSULTANT_COMMENT, { consultantId });
  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '失败' }));
    return [] as ConsultantCommentInfo[];
  }

  if (data?.adminGetConsultantAdminCommentList) {
    // dispatch(companyKnownAction(data.addCompany));
    if (data.adminGetConsultantAdminCommentList.length == 0) {
      // dispatch(showNotificationAction({ severity: 'success', message: '成功' }));
      return [] as ConsultantCommentInfo[];
    }
    // dispatch(loadingEndAction());
    return data.adminGetConsultantAdminCommentList as ConsultantCommentInfo[];
  }
  return [] as ConsultantCommentInfo[];
};

export const addConsultantComment = (consultantId: number, Comment: string): Function => async (
  dispatch: Function
): Promise<object> => {
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const { data, error } = await mutate(ADD_CONSULTANT_COMMENT, { consultantId, Comment });
  dispatch(loadingEndAction());

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '失败' }));
    return {};
  }

  if (data?.adminAddConsultantAdminComment) {
    dispatch(showNotificationAction({ severity: 'success', message: '评论成功' }));
    return data.adminAddConsultantAdminComment;
  }
  return {};
};

export const deleteConsultantComment = (commentId: number): Function => async (
  dispatch: Function
): Promise<boolean> => {
  dispatch(loadingStartAction());
  const { data, error } = await mutate(
    DELETE_CONSULTANT_COMMENT,
    { commentId },
    {
      fetchPolicy: 'no-cache',
    }
  );
  dispatch(loadingEndAction());

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '删除失败' }));
    return false;
  }

  if (data?.adminDeleteConsultantAdminComment) {
    dispatch(showNotificationAction({ severity: 'success', message: '删除成功' }));
    dispatch(loadingEndAction());
  }

  return data?.adminDeleteConsultantAdminComment ? true : false;
};
